import classes from "./modal-frame.module.css";

function ModalFrame() {
    return (
        <>
            <div className={classes.mainModalFrame}>
                
            </div>
        </>
    );
};

export default ModalFrame;